import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { CVParsing } from "../../../hook/CVParsing";
import { useTalent } from "../../../hook/useTalent";
import CustomModal from "../../UI/Modal";
import { Message } from "../../../Utilities/Reusable/Message/Message";

const validateCVDataFormat = (data) => {
  console.log("===================", data);
  const expectedFormat = {
    skillSet: "array",
    workHistory: "array",
    // certificates: "array",
  };
  // Check if data is null or undefined
  if (!data || typeof data !== "object") {
    console.error(
      "Invalid data format. Expected an object but got:",
      typeof data
    );
    return false;
  }

  for (const key in expectedFormat) {
    if (!Array.isArray(data[key])) {
      console.error(
        `Invalid format for ${key}. Expected an array but got ${typeof data[
          key
        ]}`
      );
      return false;
    }
  }

  if (data.skillSet.length === 0 || data.workHistory.length === 0) {
    console.error(
      "Either skillSet or workHistory should have at least one item"
    );
    return false;
  }

  return true;
};

const UploadResumeAiModal = ({ fileContent }) => {
  const [modalInfo, setModalInfo] = useState([]);
  const [file, setFile] = useState(null);
  const [show, setShow] = useState(false);
  const [cv, setCV] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [fileUploaded, setFileUploaded] = useState(false);

  const { uploadCV, s3Url, uploadCVLoad } = useTalent();
  const { parseCV, parseCVData, parseCVLoad } = CVParsing();
  // const { parseCV, parseCVData } = CVParsing();
  // const parseCVLoad = false;

  const data = {
    name: "IM A. SAMPLE X",
    birthDate: "",
    description: "",

    studentInfo: [
      {
        educationalBackground: "BS in Human & Social Service Administration",
        course: "",
        school: "Bellevue University",
        schoolYear: "Jan 20xx",
      },
    ],
    skillSet: [],
    workHistory: [
      {
        companyName: "Greater NE Goodwill Industries",
        employmentType: "asdas",
        experienceDate: {
          startDate: "June 2022",
          endDate: "Present",
        },
        industry: "sdsdfs",
        isPresent: 1,
        jobLevel: "Rank and File",
        jobRole:
          "Manage a caseload of twenty consumers, assist them in setting and reaching individual plans. Facilitate group sessions on Mental Illness, Stress Management and Healthy Relationships. Plan and implement social activities for consumers. Coordinate and conduct team meetings. Process billings, manage petty cash fund, and oversee operations in supervisor's absence.",
        workSetup: "Hybrid",
      },
      {
        companyName: "Ateneo",
        employmentType: "Full-time",
        experienceDate: {
          startDate: "June 2022",
          endDate: "Present",
        },
        industry: "Others",
        isPresent: 1,
        jobLevel: "Rank and File",
        jobRole:
          "Manage a caseload of twenty consumers, assist them in setting and reaching individual plans. Facilitate group sessions on Mental Illness, Stress Management and Healthy Relationships. Plan and implement social activities for consumers. Coordinate and conduct team meetings. Process billings, manage petty cash fund, and oversee operations in supervisor's absence.",
        workSetup: "Hybrid",
      },

      {
        companyName: "NU",
        employmentType: "Full-time",
        experienceDate: {
          startDate: "June 2022",
          endDate: "Present",
        },
        industry: "Others",
        isPresent: 1,
        jobLevel: "Rank and File",
        jobRole:
          "Manage a caseload of twenty consumers, assist them in setting and reaching individual plans. Facilitate group sessions on Mental Illness, Stress Management and Healthy Relationships. Plan and implement social activities for consumers. Coordinate and conduct team meetings. Process billings, manage petty cash fund, and oversee operations in supervisor's absence.",
        workSetup: "Hybrid",
      },
      {
        companyName: "Lasalle",
        employmentType: "Full-time",
        experienceDate: {
          startDate: "June 2022",
          endDate: "Present",
        },
        industry: "Others",
        isPresent: 1,
        jobLevel: "asdasd",
        jobRole:
          "Manage a caseload of twenty consumers, assist them in setting and reaching individual plans. Facilitate group sessions on Mental Illness, Stress Management and Healthy Relationships. Plan and implement social activities for consumers. Coordinate and conduct team meetings. Process billings, manage petty cash fund, and oversee operations in supervisor's absence.",
        workSetup: "Hybrid",
      },
    ],
    certificates: [],
  };

  const handleChange = async (e) => {
    setFile(e);
    setFileUploaded(true);
    if (e && e.size <= 5000000) {
      uploadCV(e, "1");
    } else {
      setCV(e, "1");
      // Handle file size error if needed
    }
  };

  useEffect(() => {
    if (fileUploaded) {
      const span = document.querySelector(
        ".sc-fqkvVR.kFhUBM, .sc-fqkvVR.cKeOpf"
      );
      if (span) {
        span.innerHTML =
          "Uploaded successfully! Please wait while the AI processes your CV.";
        span.style.fontSize = "18px"; // Adjust font size as needed

        // Remove the span with file-types class
        const fileTypeSpan = document.querySelector(".file-types");
        if (fileTypeSpan) {
          fileTypeSpan.remove();
        }
      }
    }
  }, [fileUploaded]);

  console.log(cv, file, s3Url, parseCVData);

  useEffect(() => {
    if (s3Url) {
      parseCV(s3Url);
    }
  }, [s3Url]);

  useEffect(() => {
    // fileContent(data);
    if (parseCVData) {
      if (validateCVDataFormat(parseCVData.content)) {
        fileContent(parseCVData.content);
      } else {
        console.log(parseCVData);
        setModalInfo({
          show: true,
          type: "oopsWarning",
          message: Message.ai.cvParsing[205],
          bName: "OK",
        });
      }
    }
  }, [parseCVData, fileContent]);
  //
  return (
    <>
      <div className="d-flex">
        <Button
          className="btn btn-yellow_upload-resume ms-auto mb-3 border-0"
          onClick={handleShow}
        >
          <FontAwesomeIcon icon={faCloudArrowUp} /> Upload Resume
        </Button>
      </div>
      <Modal
        className={parseCVLoad ? "loading__resume" : "upload-resume__modal"}
        show={show}
        onHide={handleClose}
        centered
      >
        {parseCVLoad ? (
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ height: "300px" }}
          >
            <img
              src={require("../../../assets/images/Logo/Brand/nexie.png")}
            ></img>
            <h2 style={{ fontWeight: "900" }}>Extracting data...</h2>
          </div>
        ) : (
          <div className="p-4">
            <FileUploader
              multiple={false}
              handleChange={handleChange}
              name="file"
              types={["PDF"]}
            />
            <div className="upload-resume__modal-icon">
              <FontAwesomeIcon icon={faCloudArrowUp} />
            </div>
            <div className="upload-resume__modal-icon-bg">
              <FontAwesomeIcon icon={faCloudArrowUp} />
            </div>
            <div className="upload-resume__modal-p">
              <p>
                {file ? `File name: ${file.name}` : "No files uploaded yet"}
              </p>
            </div>
          </div>
        )}
      </Modal>
      <CustomModal
        show={modalInfo.show}
        modalType={modalInfo.type}
        modalMessage={modalInfo.message}
        buttonName={modalInfo.bName}
        onClick={() => Message.ai.cvParsing[205] && setModalInfo({})}
      />
    </>
  );
};

export default UploadResumeAiModal;
