import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import GlobalText from "../../../assets/global/GlobalText.json";
import "../../../assets/scss/Talent/Layout/MyResume/Modal/KeySkillsModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import WarningModal from "../../WarningModal";
import SuccessModal from "../../SuccessModal";
import Loader from "../../../layouts/loader/Loader";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import Config from "../../../Config/config";
import axios from "axios";
import { updateProfile } from "../../../services/Actions/profileSlice";
import { useDispatch } from "react-redux";

const KeySkillModal = ({ isOpen, close, save, keySkillList }) => {
  const initialKeySkillList = keySkillList;
  const dispatch = useDispatch();
  const token = useAuthToken();
  const apiKey = process.env.REACT_APP_TALENT_API;
  // const [skillSet, setSkillSet] = useState(keySkillList || []);
  const [validationErrors, setValidationErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalErrorMsg, setModalErrorMsg] = useState("");
  const [isDeletingSkill, setIsDeletingSkill] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const toggleModal2 = () => {
    setModalOpen2(!modalOpen2);
  };

  const handleManageClick = () => {
    setModalOpen(false);
    close();
    save();
    // window.location.reload();
  };

  // Initialize skillSet state to manage internally
  const [skillSet, setSkillSet] = useState(initialKeySkillList);
  // console.log(initialKeySkillList);
  useEffect(() => {
    if (isOpen) {
      setSkillSet(initialKeySkillList);
    }
  }, [isOpen]);

  let handleChange = (key, event) => {
    let newInputValues = [...skillSet];
    newInputValues[key] = {
      ...newInputValues[key],
      [event.target.name]: event.target.value,
    };

    if (event.target.name === "skill" && event.target.value.length > 600) {
      let newValidationErrors = [...validationErrors];
      newValidationErrors[key] = "Skill cannot exceed 600 characters";
      setValidationErrors(newValidationErrors);
    } else {
      let newValidationErrors = [...validationErrors];
      newValidationErrors[key] = "";
      setValidationErrors(newValidationErrors);
    }

    setSkillSet(newInputValues);
  };

  let addKeySkill = () => {
    setSkillSet([...skillSet, { skill: "", level: "", experience: "" }]);
  };

  const deleteKeySkill = (indexToDelete) => {
    // Filter out the skill at index 'indexToDelete'
    const newInputValues = skillSet.filter(
      (_, index) => index !== indexToDelete
    );

    console.log(newInputValues);

    console.log(newInputValues);

    setSkillSet(newInputValues);
    setIsDeletingSkill(true);
    // console.log(skillSet);

    // const newValidationErrors = validationErrors.filter(
    //   (_, index) => index !== indexToDelete
    // );
    // setValidationErrors(newValidationErrors);
  };

  const checkDuplicateSkills = () => {
    const skillNames = skillSet.map((skill) =>
      skill.skill.trim().toLowerCase()
    );
    const duplicates = skillNames.filter(
      (item, index) => skillNames.indexOf(item) !== index
    );
    return duplicates.length > 0;
  };

  const submitKeySkills = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const errors = [];
    skillSet.forEach((val, key) => {
      if (val.skill.trim() === "") {
        errors[key] = "All fields are required";
      } else if (val.experience.trim() === "") {
        val.experience = "Less than 1 year";
      } else if (val.skill.length > 600) {
        errors[key] = "Skill cannot exceed 600 characters";
      }

      if (val.level.trim() === "") {
        val.level = "Beginner";
      }
    });

    if (checkDuplicateSkills()) {
      setValidationErrors(["Duplicate skills are not allowed"]);
      setIsLoading(false);
      return;
    }

    if (errors.length > 0) {
      setValidationErrors(errors);
      setIsLoading(false);
      return;
    }

    try {
      const payload = {
        skillSet,
      };
      console.log(payload);
      const response = await axios.post(
        `${Config.nxtApi}/api/talent/setskill`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(skillSet);

      dispatch(updateProfile({ skillSet: skillSet }));
      toggleModal();
    } catch (err) {
      console.log(err);
      toggleModal2();
      setModalErrorMsg(err.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setValidationErrors([]);
    }
  }, [isOpen]);

  return (
    <div>
      <Modal
        isOpen={isOpen ? isOpen : false}
        toggle={close}
        className="modal-container"
        centered
      >
        <ModalHeader toggle={close} className="modal-header-container">
          Key Skills
        </ModalHeader>
        <ModalBody className="modal-body-container">
          <p>
            Your key skills are the first thing recruiters notice in your
            profile. Write concisely what makes you unique and the right person
            for the job you're seeking.
          </p>
          <div>
            {skillSet.map((val, key) => {
              // console.log(val, key);
              return (
                <FormGroup className="skill-group" key={key}>
                  <Input
                    type="text"
                    name="skill"
                    placeholder="Enter Skill"
                    className="skill-text"
                    defaultValue={val.skill || ""}
                    onChange={(event) => {
                      handleChange(key, event);
                    }}
                  />
                  <Input
                    type="select"
                    name="level"
                    defaultValue={val.level || ""}
                    onChange={(event) => {
                      handleChange(key, event);
                    }}
                  >
                    {GlobalText.skillLevel.map((option, key) => {
                      return (
                        <option
                          key={key}
                          defaultValue={option.value}
                          disabled={option.disabled}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                  </Input>
                  <Input
                    type="select"
                    className="year"
                    name="experience"
                    defaultValue={val.experience || ""}
                    onChange={(event) => {
                      handleChange(key, event);
                    }}
                  >
                    {GlobalText.skillYear.map((option, key) => {
                      return (
                        <option
                          key={key}
                          defaultValue={option.value}
                          disabled={option.disabled}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                  </Input>

                  <Button
                    onClick={() => deleteKeySkill(key)}
                    className="deleteBtn"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </FormGroup>
              );
            })}
            {validationErrors.map((error, key) => (
              <span style={{ color: "red" }} key={key}>
                {error}
              </span>
            ))}
            <h6 onClick={() => addKeySkill()}>
              <FontAwesomeIcon icon={faPlus} /> Add Skill
            </h6>
          </div>
        </ModalBody>
        <ModalFooter className="modal-footer-container">
          <Button className="cancel" onClick={close}>
            Cancel
          </Button>
          <Button className="save" onClick={submitKeySkills}>
            {isLoading ? <Loader /> : "Save"}
          </Button>
        </ModalFooter>
      </Modal>
      <SuccessModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        iconImage={require("../../../assets/images/SuccessIcon.png")}
        modalLabel="Success"
        modalSublabel="Skill Set is added"
        confirmButtonText="Ok"
        onConfirm={handleManageClick}
      />
      <WarningModal
        modalOpen={modalOpen2}
        toggleModal={toggleModal2}
        iconImage={require("../../../assets/images/WarningIcon.png")}
        modalLabel="Warning"
        modalSublabel={modalErrorMsg}
        confirmButtonText="Confirm"
        onConfirm={toggleModal2}
      />
    </div>
  );
};

export default KeySkillModal;
