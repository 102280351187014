import React, { useEffect } from "react";
import "../../../assets/scss/Client/Layout/TalentList/JobList.scss";
import logo from "../../../assets/images/Icon/iconBuilding.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookBookmark,
  faClock,
  faLocationPin,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { List } from "react-content-loader";
import JobListContent from "./JobListDetails/JobListContent";
import Config from "../../../Config/config";
import { JobAI } from "../../../hook/JobAI";

const RecommendedJobs = ({
  joblist,

  totalLength,
  loadMoreData,
  isLoading,
}) => {
  const navigate = useNavigate();
  const apiKey = process.env.REACT_APP_TALENT_API;

  const handleLoadMore = () => {
    loadMoreData(joblist.length); // Pass the current number of displayed jobs
  };

  const handleClick = (job_id, id, createdAt) => {
    navigate(`/talent/job-list-details/${job_id}`);
    const obj = {
      job_id: job_id,
      id: id,
      createdAt: createdAt,
    };

    localStorage.setItem("jobPayload", JSON.stringify(obj));
  };

  return (
    <div className="job-list-client">
      <h6>{joblist?.length} RECOMMENDED JOBS</h6>
      <hr className="line-separator" />

      <div>
        {!joblist ? (
          <List />
        ) : joblist.length === 0 ? (
          <div className="no-data-message pb-5">
            No matches were found. Try updating your profile for better job
            recommendations.
          </div>
        ) : (
          joblist.map((list) => {
            return (
              <div
                className="job-details-container"
                onClick={() =>
                  handleClick(list.job_id, list.id, list.postingDate.startDate)
                }
                key={list.id}
              >
                <div className="img-container">
                  <img
                    crossOrigin="anonymous"
                    src={`${
                      Config.nxtApi
                    }/api/jobs/job/company/logo?clientId=${encodeURIComponent(
                      list.clientId
                    )}&thumbNail=yes`}
                    alt="logo"
                    onError={(e) => {
                      e.target.src = logo;
                      e.target.classList.add("errorImg");
                    }}
                  />
                </div>

                <div className="details">
                  <h4>{list.jobTitle}</h4>
                  <div className="additional-details">
                    <h5>{list.companyName}</h5>

                    <div className="information">
                      <div className="information-group">
                        <h6 className="jobId">Job ID : {list.job_id}</h6>
                        <FontAwesomeIcon icon={faBuilding} className="icon" />
                        <h6>{list.city}</h6>
                      </div>
                      <div className="information-group">
                        <FontAwesomeIcon
                          icon={faBookBookmark}
                          className="icon"
                        />
                        <h6>{list.workArrangement}</h6>
                      </div>
                      <div className="information-group">
                        <FontAwesomeIcon icon={faClock} className="icon" />
                        <h6>
                          {moment(list.postingDate.startDate).format(
                            "MMMM D, YYYY"
                          )}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <JobListContent jobid={list.id} /> */}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default RecommendedJobs;
