import { useEffect, useState } from "react";

import "../../assets/scss/Talent/Page/MyResume.scss";
import { Container } from "reactstrap";
import SidebarTalent from "../../components/LayoutTalent/SidebarTalent";
import { TabTitle } from "../../Utilities/FunctionTitle";
import FormProfile from "../../components/FormTalent/FormProfile";
import BannerTalent from "../../components/LayoutTalent/BannerTalent";
import HeaderTalent from "../../components/LayoutTalent/HeaderTalent";
import KeySkills from "../../components/LayoutTalent/MyResumeLayout/KeySkills";
import WorkHistory from "../../components/LayoutTalent/MyResumeLayout/WorkHistory";
import DocumentsCV from "../../components/LayoutTalent/MyResumeLayout/DocumentsCV";
import DocumentCertificates from "../../components/LayoutTalent/MyResumeLayout/DocumentCertificates";
import TalentAnalytics from "../../Utilities/Analytics/TalentAnalytics";
import axios from "axios";
import Config from "../../Config/config";
import { useAuthToken } from "../../Utilities/Auth/Token";
import UploadResumeAiModal from "../../components/FormTalent/MyResume/UploadResumeAiModal";
import { useSelector } from "react-redux";
import { CVParsing } from "../../hook/CVParsing";
import Footer from "../LandingPage/Components/Footer";
import { useLocation } from "react-router-dom";

const MyResumeTalent = () => {
  TabTitle("NXT | My Resume");
  const isAIMLOn = useSelector((state) => state.reducer.aimlInfo?.isOn);
  // const isAIMLOn = true;

  const [fileContent, setFileContent] = useState(null);
  console.log("fileContent ", fileContent);
  console.log(isAIMLOn);
  const [workHistory, setworkHistory] = useState({});
  const [certificates, setCertificates] = useState();
  const [workHistoryloading, setIsWorkHistoryLoading] = useState(true);
  const [certificatesLoading, setCertificatesLoading] = useState(true);
  const token = useAuthToken();
  const location = useLocation();
  useEffect(() => {
    async function getWorkHistory() {
      console.log(11111);
      setIsWorkHistoryLoading(true);
      const response = await axios.post(
        `${Config.nxtApi}/api/talent/workhistories`,
        { numRow: 10, sortKeys: "desc" },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setIsWorkHistoryLoading(false);
      console.log(response);
      setworkHistory(response);
    }
    getWorkHistory();
  }, [token]);

  useEffect(() => {
    async function getCertificates() {
      setCertificatesLoading(true);
      const response = await axios.post(
        `${Config.nxtApi}/api/talent/certificates`,
        { numRow: 100, sortKeys: "desc" },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setCertificatesLoading(false);
      setCertificates(response);
    }
    if (!workHistoryloading) {
      getCertificates();
    }
  }, [workHistoryloading, token]);

  const { parseCVData, parseCVLoad } = CVParsing();

  useEffect(() => {
    console.log("sdfsfsadfsg", parseCVData);
  }, [parseCVData]);

  console.log(parseCVData, fileContent);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (
        (fileContent?.workHistory && fileContent?.workHistory?.length > 0) ||
        fileContent?.skillSet
      ) {
        event.preventDefault();
        event.returnValue = "";

        const confirmLeave = window.confirm(
          "You have unsaved work history. Are you sure you want to leave this page?"
        );

        if (confirmLeave) {
          window.location.reload();
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [fileContent]);

  return (
    <div className="background-imagePost-myresume">
      <TalentAnalytics />
      <div className="header-fixed">
        <HeaderTalent />
      </div>
      <div className="jobposting-banner">
        <BannerTalent />
      </div>

      <Container className="myresume-body">
        <div className="my-resume-body-inner">
          <div>
            <div className="sidebar-container">
              <SidebarTalent />
            </div>
          </div>
          <div className="company-profile-container">
            {isAIMLOn && <UploadResumeAiModal fileContent={setFileContent} />}
            <KeySkills hasAI={fileContent} />
            {!workHistoryloading && (
              <WorkHistory workHistory={workHistory} hasAI={fileContent} />
            )}

            <DocumentsCV />
            {!certificatesLoading && (
              <DocumentCertificates certificates={certificates} />
            )}
          </div>
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default MyResumeTalent;
