import React, { useEffect, useState } from "react";
import { Alert, Button } from "reactstrap";
import "../../../assets/scss/Talent/Layout/MyResume/KeySkills.scss";
import KeySkillModal from "../../FormTalent/MyResume/KeySkillModal";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMultiply } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../layouts/loader/Loader";
import { List } from "react-content-loader";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import Config from "../../../Config/config";
import { useSelector } from "react-redux";
const KeySkills = ({ hasAI }) => {
  const skillSetFromAI = hasAI?.skillSet || [];
  const skillSetFromProfile = useSelector(
    (state) => state.reducer.profile?.currentProfile?.skillSet || []
  );

  const [talentInfo, setTalentInfo] = useState({
    skillSet: [...skillSetFromProfile, ...skillSetFromAI],
  });

  const [loading, setLoading] = useState(false);
  const [showKeySkillModal, setShowKeySkillModal] = useState(false);
  const [btnIfAi, setBtnIfAi] = useState(hasAI?.skillSet.length > 0);

  const updateTalentInfo = () => {
    setTalentInfo({
      skillSet: [...skillSetFromProfile, ...skillSetFromAI],
    });
  };

  useEffect(() => {
    if (skillSetFromProfile.length > 0 || skillSetFromAI.length > 0) {
      updateTalentInfo();
    }
    setBtnIfAi(hasAI?.skillSet.length > 0);
  }, [skillSetFromProfile, skillSetFromAI]);

  const deleteAIData = () => {
    setBtnIfAi(false); // Update btnIfAi state
    setTalentInfo({
      skillSet: skillSetFromProfile,
    });
  };

  const clickKeySkillsBtn = () => {
    setShowKeySkillModal(true);
  };

  useEffect(() => {
    if (talentInfo.skillSet) {
      const beginnerSkills = talentInfo.skillSet.filter(
        (skill) => skill.level === "Beginner" || skill.level === ""
      );
      const intermediateSkills = talentInfo.skillSet.filter(
        (skill) => skill.level === "Intermediate"
      );
      const advanceSkills = talentInfo.skillSet.filter(
        (skill) => skill.level === "Advance"
      );
      setBeginner(beginnerSkills);
      setIntermediate(intermediateSkills);
      setAdvance(advanceSkills);
    }
  }, [talentInfo]);

  const [beginner, setBeginner] = useState([]);
  const [intermediate, setIntermediate] = useState([]);
  const [advance, setAdvance] = useState([]);

  return (
    <div className="key-skills-talent">
      <div className="header-container">
        <h6>KEY SKILLS</h6>
        <Button onClick={() => clickKeySkillsBtn()}>
          {btnIfAi ? "Save Skill Updates" : "Add / Edit Key Skills"}
        </Button>
      </div>
      <hr className="line-seperator" />
      <div className="body-container">
        {loading ? (
          <>
            <List />
          </>
        ) : (
          <>
            <h5>Beginner</h5>
            <div className="level-container">
              {beginner.length > 0 ? (
                <>
                  {beginner.map((info, key) => (
                    <div
                      className="level-content level-content__beginner"
                      key={key}
                    >
                      <p>
                        {info.skill} - {info.experience}
                      </p>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <Alert color="secondary" style={{ width: "100%" }}>
                    No beginner skill
                  </Alert>
                </>
              )}
            </div>
            <h5>Intermediate</h5>
            <div className="level-container">
              {intermediate.length > 0 ? (
                <>
                  {intermediate?.map((info, key) => (
                    <div
                      className="level-content level-content__intermediate"
                      key={key}
                    >
                      <p>
                        {info.skill} - {info.experience}
                      </p>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <Alert color="secondary" style={{ width: "100%" }}>
                    No intermediate skill
                  </Alert>
                </>
              )}
            </div>
            <h5>Advance</h5>
            <div className="level-container">
              {advance.length > 0 ? (
                <>
                  {advance?.map((info, key) => (
                    <div
                      className="level-content level-content__advance"
                      key={key}
                    >
                      <p>
                        {info.skill} - {info.experience}
                      </p>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <Alert color="secondary" style={{ width: "100%" }}>
                    No advance skill
                  </Alert>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <KeySkillModal
        isOpen={showKeySkillModal}
        close={() => setShowKeySkillModal(false)}
        keySkillList={talentInfo.skillSet}
        save={deleteAIData}
      />
    </div>
  );
};

export default KeySkills;
